<template>
  <!-- 账款管理 -->
  <div class="page-card-demo  page-info-content">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getDate"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    />
    <div class="page-container-table">
      <div class="list-operation">
        <el-button type="primary" size="small" @click="handleAdd">
          新增
        </el-button>
      </div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <!-- <template #status="{ row }">
          <span>{{ row.status === 0 ? '进行中' : '已下架' }}</span>
          <span class="reason">{{ row.reason === 1 ? '(售出下架)' : row.reason === 2 ? '(超时下架)' : row.reason === 3 ? '(主动下架)': '' }}</span>
        </template> -->
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getDate" />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Pagination from '@/components/Pagination2'
import Table from '@/components/Table.vue'
import { selectGuarProdInfoList } from '@/api/tradeFinancing'
export default {
  components: { FormSearch, Pagination, Table },
  data() {
    return {
      formInline: {
        prodName: '', // 产品名称
        guarCmpName: '', // 担保方
        coreCmpName: '' // 核心企业
      },
      formItemArr: [
        { type: 'input', label: '产品名称', value: 'prodName', width: '120px' },
        { type: 'input', label: '担保方', value: 'guarCmpName', width: '80px' },
        { type: 'input', label: '核心企业', value: 'coreCmpName', width: '80px' }],
      itemData: [
        { label: '产品编码', prop: 'prodNo', width: 220 },
        { label: '产品名称', prop: 'prodName', width: 120 },
        { label: '担保方', prop: 'guarCmpName', width: 280 },
        { label: '项目总额度', prop: 'prodAmt', width: 180 },
        { label: '项目类型', prop: 'guarTypeName', width: 180 },
        { label: '核心企业', prop: 'coreCmpName', width: 180 },
        { label: '状态', prop: 'prodState', child: this.$store.getters.getDictionaryItem('GUAR_PROD_STATE'), width: 180 }
      ],
      operationButton: [
        { bType: 'primary', label: '查看详情', handleEvent: this.viewDetails },
        { bType: 'primary', label: '审核', handleEvent: this.viewDetails, child: [{ val: 'auditButton' }] },
        { bType: 'primary', label: '编辑', handleEvent: this.viewDetails, child: [{ val: 'editButton' }] },
        { bType: 'primary', label: '启用', handleEvent: this.viewDetails, child: [{ val: 'startButton' }] },
        { bType: 'primary', label: '停用', handleEvent: this.viewDetails, child: [{ val: 'stopButton' }] }
      ],
      listData: [],
      total: 0,
      loading: false,
      dateRange: []
    }
  },
  methods: {
    getDate(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1,
          state: '',
          goodsCategoryArr: []
        }
        this.dateRange = []
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      selectGuarProdInfoList(this.formInline, res => {
        this.listData = res.data.list.map(item => {
          if (item.prodState === '01' || item.prodState === '02') item.auditButton = true
          if (item.prodState === '04' || item.prodState === '05') item.editButton = true
          if (item.prodState === '04') item.startButton = true
          if (item.prodState === '03') item.stopButton = true
          return item
        })
      })
    },
    getTabChange(val) {
      if (!val || val === '0') { this.formInline.state = '' } else { this.formInline.state = val }
      this.getDate(true)
    },
    viewDetails() {

    },
    handleAdd() {
      this.$router.push('/productManage/addGuarantProduct')
    }
  }
}
</script>

<style>

</style>
